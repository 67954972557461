<template>
  <transition name="zoom">
    <slot></slot>
  </transition>
</template>

<script>
export default {
  name: 'MyZoomTransition'
}
</script>

<style scoped lang="scss">
.zoom-enter-active, .zoom-leave-active {
  transition: opacity .15s linear, transform .15s linear;
}
.zoom-enter, .zoom-leave-to {
  opacity: 0;
  transform: scale3d(0.5, 0.5, 1);
}
</style>
