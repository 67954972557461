import axios from 'axios'
import utility from '@/common/utility'
import auth from '@/common/auth'

class RestService {
  constructor (resourceName) {
    this.resourceName = resourceName
  }

  list (pageInfo, searchModel) {
    let params = {}
    Object.assign(params, pageInfo, searchModel)

    return axios.get(utility.getRestFullUrl(this.resourceName), {
      params: params,
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  get (resourceId) {
    return axios.get(utility.getRestFullUrl(this.resourceName, resourceId), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  add (resource) {
    resource.generatePrimaryKey()
    return axios.post(utility.getRestFullUrl(this.resourceName), resource, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  edit (resource) {
    return axios.put(utility.getRestFullUrl(this.resourceName, resource.resourceId), resource, {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  delete (resourceId) {
    return axios.delete(utility.getRestFullUrl(this.resourceName, resourceId), {
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }

  batchDelete (ids) {
    return axios.post(utility.getRestFullUrl(this.resourceName, 'batchDelete'), null, {
      params: {
        ids
      },
      headers: {
        'Authorization': utility.getAuthHeader(auth.getToken())
      }
    })
  }
}

export default RestService
