import BaseService from '@/services/BaseService'

let resourceName = 'picture'

class PictureService extends BaseService {
}

const pictureService = new PictureService(resourceName)

export default pictureService
