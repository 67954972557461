<template>
  <div ref="page">
    <my-search-bar>
      <el-form :inline="true">
        <el-form-item>
          <el-input
            v-model="searchModel.pictureName"
            placeholder="图片名称"
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-input
            v-model="searchModel.pictureNameSpell"
            placeholder="图片简称"
            >
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" class="mr-2" @click="handleSearch">查询</el-button>
        </el-form-item>
        <el-form-item>
          <el-button-group>
            <el-button type="primary" @click="handlePrev" icon="el-icon-arrow-left">上一页</el-button>
            <el-button type="primary">{{ this.pageIndex }}</el-button>
            <el-button type="primary" @click="handleNext">下一页<i class="el-icon-arrow-right el-icon--right"></i></el-button>
          </el-button-group>
        </el-form-item>
      </el-form>
    </my-search-bar>

    <div class="picture-index" ref="pictureIndex">
      <div class="image-wrapper"
        v-for="picture in pictureList"
        :key="picture.pictureGuid"
        >
        <el-checkbox v-model="picture.checked" @change="handleCheckboxChange(picture)" class="checkbox" v-if="selection" />
        <el-card
          class="box-card"
          shadow="always"
          :body-style="{ padding: '0', height: '100%' }"
          >
          <el-image
            @click="handleImageClick(picture)"
            @dblclick="handleImageDblClick(picture)"
            :src="'http://47.93.222.187:5008/' + picture.pictureGuid + '.jpg'"
            fit="fill"
            lazy
            style="width: 100%; height: 100%;"
            >
          </el-image>
          <div class="text-wrapper text-muted text-left">
            <el-tooltip placement="bottom" :open-delay="500" :content="picture.pictureName">
              <span>{{ picture.pictureName }}</span>
            </el-tooltip>
            <i @click="handleDelete(picture)" class="far fa-trash-alt text-secondary" style="font-size: 10px;"></i>
          </div>
        </el-card>
      </div>
      <div class="image-wrapper">
        <el-upload
          :action="action"
          :show-file-list="false"
          list-type="picture-card"
          multiple
          :on-success="handleSearch"
          :headers="headers"
          >
          <div style="width: 100%; text-align: center">
            <i class="el-icon-plus"></i>
          </div>
        </el-upload>
      </div>
      <div
        v-for="wrapper in emptyWrappers"
        :key="wrapper.id"
        class="image-wrapper">
      </div>
    </div>

    <my-zoom-transition>
      <div class="image-holder" v-if="isShow">
        <el-image
          :src="'http://47.93.222.187:5008/' + currentPicture.pictureGuid + '.jpg'"
          fit="fill"
          lazy
          style="width: 100%; height: 100%;"
          >
        </el-image>
      </div>
    </my-zoom-transition>
    <my-fade-transition>
      <div class="modal-backdrop show" v-if='isShow' @click="handleModalClick"></div>
    </my-fade-transition>
  </div>
</template>

<script>
import pictureService from '@/services/pictureService'
import MySearchBar from '@/components/MySearchBar/MySearchBar'
import auth from '@/common/auth'
import config from '@/config'
import utility from '@/common/utility'
import PageInfo from '@/support/PageInfo'
import MyFadeTransition from '@/components/MyTransitions/MyFadeTransition'
import MyZoomTransition from '@/components/MyTransitions/MyZoomTransition'

export default {
  name: 'PictureIndex',
  mixins: [
  ],
  components: {
    MyZoomTransition,
    MyFadeTransition,
    MySearchBar
  },
  props: {
    selection: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      searchModel: {
        tenderGuid: auth.getUserInfo().tenderGuid,
        pictureName: '',
        pictureNameSpell: ''
      },
      pictureList: [],
      isShow: false,
      currentPicture: null,
      selectedPicture: null,
      pageIndex: 1,
      pageSize: 10,
      totalCount: 0
    }
  },
  computed: {
    action () {
      return `${config.serviceHost}/picture/UploadFile`
    },
    headers () {
      return {
        Authorization: utility.getAuthHeader(auth.getToken())
      }
    },
    emptyWrappers () {
      let len = 5 - this.pictureList.length
      if (len > 0) {
        return Array(len).fill(0).map(item => {
          return {
            id: utility.getUuid()
          }
        })
      }

      return []
    }
  },
  filters: {
    imageSrcFilter (pictureGuid) {
      return `${config.serviceHost}/picture/GetPictureByPictureGuid?pictureGuid=${pictureGuid}`
    }
  },
  watch: {
    tenderGuid (newVal) {
      newVal && this._getPictureList()
    }
  },
  methods: {
    getSelectedPicture () {
      return this.selectedPicture
    },
    handleSearch () {
      this._getPictureList()
    },
    handleImageDblClick (picture) {
      this.currentPicture = picture
      this.isShow = true
    },
    handleImageClick (picture) {
      this.pictureList.filter(pic => pic.pictureGuid !== picture.pictureGuid).forEach(pic => {
        pic.checked = false
      })
      picture.checked = !(picture.checked)
      this.selectedPicture = picture.checked ? picture : null
      this.$emit('checkPicture', picture)
    },
    handleCheckboxChange (picture) {
      this.pictureList.filter(pic => pic.pictureGuid !== picture.pictureGuid).forEach(pic => {
        pic.checked = false
      })
      this.selectedPicture = picture.checked ? picture : null
    },
    handleModalClick () {
      this.isShow = false
      this.currentPicture = null
    },
    handleDelete (picture) {
      this.$confirm('确定要删除吗？')
        .then(() => {
          pictureService.delete(picture.pictureGuid)
            .then(res => {
              console.log(res)
              if (res.data.code === 1) {
                this.$message({
                  type: 'success',
                  message: '删除成功！'
                })
                this._getPictureList()
              } else {
                this.$message({
                  type: 'error',
                  message: res.data.msg
                })
              }
            })
            .catch(err => {
              console.log(err)
              this.$message({
                type: 'error',
                message: '删除失败！'
              })
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '您已取消删除！'
          })
        })
    },
    _getPictureList () {
      pictureService.list(new PageInfo({ pageIndex: this.pageIndex, pageSize: this.pageSize, fieldOrder: 'createTime desc' }), this.searchModel)
        .then(res => {
          if (res.data.code === 1) {
            this.totalCount = res.data.pageCount
            this.pictureList = res.data.data.map(picture => {
              picture.checked = false
              return picture
            })
          } else {
            this.$message({
              type: 'error',
              message: res.data.msg
            })
          }
        })
        .catch(err => {
          console.log(err)
          this.$message({
            type: 'error',
            message: '列表加载失败！'
          })
        })
    },
    computePageSize () {
      let pageWidth = this.$refs.pictureIndex.clientWidth
      let pageHeight = this.$refs.page.parentNode.clientHeight - 80
      let colCount = Math.floor((pageWidth + 10) / 190)
      let rowCount = Math.floor((pageHeight + 20) / 175)
      let pageSize = colCount * rowCount - 1
      if (this.pageSize !== pageSize) {
        this.pageSize = pageSize
        this._getPictureList()
      }
    },
    handlePrev () {
      if (this.pageIndex > 1) {
        --this.pageIndex
        this._getPictureList()
      } else {
        this.$message({
          type: 'info',
          message: '已经是第一页！'
        })
      }
    },
    handleNext () {
      const maxPage = Math.ceil((this.totalCount * 1.0) / this.pageSize)
      if (this.pageIndex < maxPage) {
        ++this.pageIndex
        this._getPictureList()
      } else {
        this.$message({
          type: 'info',
          message: '已经是最后一页！'
        })
      }
    }
  },
  mounted () {
    window.addEventListener('resize', this.computePageSize)
    this.computePageSize()
    this._getPictureList()
  }
}
</script>

<style scoped lang="scss">
.picture-index {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(180px, 1fr));
  grid-gap: 20px 10px;
}
.image-wrapper {
  position: relative;
  text-align: center;
  width: 100%;
  height: 155px;
}
.image-wrapper .checkbox {
  z-index: 99;
  position: absolute;
  left: 0;
  top: 0;
  margin: 0;
  padding: 0;
  margin-top: -2px;
  margin-left: 1px;
}
.text-wrapper {
  background-color: rgb(240, 241, 242);
  line-height: 25px;
  padding: 0 3px;
  display: grid;
  grid-template-columns: 1fr 15px;
  i {
    line-height: 20px;
  }
}
.text-wrapper span {
  line-height: 20px;
  display: inline-block;
  font-size: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
::v-deep .el-card {
  height: 100%;
  cursor: pointer;
  display: grid;
  grid-template-rows: calc(100% - 25px) 25px;
}
.image-holder {
  position: fixed;
  top: 200px;
  left: 0;
  margin-left: calc(50% - 400px);
  z-index: 1050;
  width: 800px;
  height: 480px;
  padding: 10px;
  background-color: #eee;
  overflow: hidden;
}
</style>
